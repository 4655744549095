import { graphql } from 'babel-plugin-relay/macro';

export const QueryDataQuery = graphql`
  query QueryDataQuery($lessonUuid: uuid!) {
    appt_group_connection(where: {uuid: { _eq: $lessonUuid }}) {
      edges {
        node {
          ...QueryDataLessonDetailsFragment
          ...QueryDataRoomLayoutFragment
        }
      }
    }
  }
`;

export const QueryDataLessonDetailsFragment = graphql`
  fragment QueryDataLessonDetailsFragment on appt_group {
    type
    type_conversational_level
    skills {
      description_en
      display_order
      title_en
      uuid
      skills_levels {
        display_order
        title_en
        skills_categories {
          title_en
        }
      }
    }
  }
`;

export const QueryDataRoomLayoutFragment = graphql`
  fragment QueryDataRoomLayoutFragment on appt_group {
    appt_group_layout {
      id
      layout
    }
  }
`;
