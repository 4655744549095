/**
 * @generated SignedSource<<7b9baf96cf1cd28a43142f5fdfdda0e9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueryDataQuery$variables = {
  lessonUuid: any;
};
export type QueryDataQuery$data = {
  readonly appt_group_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"QueryDataLessonDetailsFragment" | "QueryDataRoomLayoutFragment">;
      };
    }>;
  };
};
export type QueryDataQuery = {
  response: QueryDataQuery$data;
  variables: QueryDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lessonUuid"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "lessonUuid"
          }
        ],
        "kind": "ObjectValue",
        "name": "uuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display_order",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_en",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueryDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "appt_groupConnection",
        "kind": "LinkedField",
        "name": "appt_group_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appt_groupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appt_group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "QueryDataLessonDetailsFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "QueryDataRoomLayoutFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueryDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "appt_groupConnection",
        "kind": "LinkedField",
        "name": "appt_group_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "appt_groupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "appt_group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type_conversational_level",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "skills",
                    "kind": "LinkedField",
                    "name": "skills",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description_en",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uuid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "skills_levels",
                        "kind": "LinkedField",
                        "name": "skills_levels",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "main_skills_categories",
                            "kind": "LinkedField",
                            "name": "skills_categories",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "appt_group_layout",
                    "kind": "LinkedField",
                    "name": "appt_group_layout",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "layout",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b160088d16ccbf2e58fbf4d5845ca9e",
    "id": null,
    "metadata": {},
    "name": "QueryDataQuery",
    "operationKind": "query",
    "text": "query QueryDataQuery(\n  $lessonUuid: uuid!\n) {\n  appt_group_connection(where: {uuid: {_eq: $lessonUuid}}) {\n    edges {\n      node {\n        ...QueryDataLessonDetailsFragment\n        ...QueryDataRoomLayoutFragment\n        id\n      }\n    }\n  }\n}\n\nfragment QueryDataLessonDetailsFragment on appt_group {\n  type\n  type_conversational_level\n  skills {\n    description_en\n    display_order\n    title_en\n    uuid\n    skills_levels {\n      display_order\n      title_en\n      skills_categories {\n        title_en\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment QueryDataRoomLayoutFragment on appt_group {\n  appt_group_layout {\n    id\n    layout\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc2ce4efa1e9feda8216cd6dd27b2164";

export default node;
